@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply text-sm xs:text-base;
    font-family: Montserrat, system-ui, sans-serif;
  }

  body {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  }

  h1 {
    @apply text-xl xs:text-2xl font-bold;
  }

  h2 {
    @apply text-lg xs:text-xl font-bold;
  }
}
/*
  Adds Utility to hide scrollbar to tailwind
    https://github.com/tailwindlabs/tailwindcss/discussions/2394
    https://github.com/tailwindlabs/tailwindcss/pull/5732
*/
@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
/*  https://stackoverflow.com/a/69308190 */
input[type='text']:focus {
  box-shadow: none;
}

/* animations */
.slideInBottom {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInBottom;
}

@keyframes slideInBottom {
  from {
    opacity: 0;
    transform: translateY(30%);
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
  }
}
/* animations */
.slideInTop {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: slideInTop;
}

@keyframes slideInTop {
  from {
    opacity: 0;
    transform: translateY(-20%);
    pointer-events: none;
  }
  to {
    opacity: 1;
    pointer-events: auto;
    transform: translateY(0%);
  }
}
.fadeIn {
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-name: fadeIn;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
/**
 * Makes sure h-screen works on mobile Safari. By default h-screen
 * does not take into account the height of the address bar, causing
 * weird behaviour when scrolling — sometimes the height will be correct
 * and sometimes it won't, depending on whether the address bar is
 * in 'collapsed' state or not.
 * @see: https://benborgers.com/posts/tailwind-h-screen
 */
@supports (-webkit-touch-callout: none) {
  .h-screen {
    height: -webkit-fill-available;
  }
}

/* styles.css */
html.todesktop,
html.todesktop div {
  cursor: default !important;
}

/* make header draggable on desktop app */
html.todesktop header {
  -webkit-app-region: drag;
}
html.todesktop header button {
  -webkit-app-region: no-drag;
}

html.todesktop .logo {
  display: none;
}

html.todesktop .logo {
  display: none;
}

.desktop-only {
  display: none;
}

html.todesktop .desktop-only {
  display: block;
}

html.todesktop .desktop-hidden {
  display: none;
}

html.todesktop header {
  margin-top: -14px;
}

html.todesktop header nav {
  margin-top: 8px;
}

html.todesktop aside {
  margin: 0px -6px;
}

html.todesktop .desktop-transparent {
  background: transparent !important;
  border: none !important;
}
@layer components {
  /* slider */
  .slider {
    @apply relative flex h-4 w-40 select-none items-center;
  }

  .slider > .slider-track {
    @apply relative h-1 flex-grow rounded-md bg-gray-400;
  }

  .slider .slider-range {
    @apply absolute h-full rounded-full bg-gray-700;
  }

  .slider .slider-thumb {
    @apply block h-3 w-3 cursor-pointer rounded-full bg-gray-700 transition-all;
  }

  .slider .slider-thumb:hover {
    @apply bg-gray-600;
  }

  .slider .slider-thumb:focus {
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.2);
  }
}
.rct-node {
  padding: 1px;
}
.react-checkbox-tree {
  overflow: auto;
  max-height: 500px;
}

.topic .rct-text {
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 3px;
  text-align: center;
}

.rct-checkbox svg {
  width: 18px;
  height: 18px;
}
/*.topic .rct-text:hover {
  cursor: pointer;
  padding: 8px;
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 200 / var(--tw-bg-opacity));
} */

.sbdocs.sbdocs-h1 {
  font-family: Montserrat, system-ui, sans-serif !important;
  font-weight: 600;
  font-size: 36px;
  margin: 0 0 82px 0;
}

.sbdocs.sbdocs-h2 {
  font-size: 20px;
  font-weight: 500;
  border: none;
  border-top: 2px solid rgba(0, 0, 0, 0.12);
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  margin: 82px 0 0 0;
}

.sbdocs.sbdocs-p {
  max-width: 560px;
  margin: 0 0 12px 0;
}

.sbdocs.sbdocs-content {
  width: 1200px;
  max-width: 100%;
}

/** Docs table **/
.custom-args-wrapper {
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 1rem;
}

.docblock-argstable-body {
  box-shadow: none !important;
  font-size: 14px;
}

/* .docblock-argstable-head tr th, */
.docblock-argstable-body tr td {
  /* padding-left: 0!important; */
  padding: 20px !important;
}

/** Column titles **/
.docblock-argstable-body tr td > span,
.docblock-argstable-head tr th > span {
  color: #525252 !important;
  font-weight: 500 !important;
}

.docblock-argstable-body div p,
.docblock-argstable-body div span {
  color: #8f8f8f !important;
}

/** Custom components **/
.story-title {
  margin: 0 0 82px 0;
}

.story-title.offset {
  margin-top: 100px;
}

.story-title h1 {
  font-family: Montserrat, system-ui, sans-serif !important;
  font-weight: 600;
  font-size: 36px;
}

.story-title h1 span {
  color: #9ca3af;
  font-family: 'Inter var';
  font-weight: normal;
  display: inline-block;
  margin-left: 8px;
}

.story-title p {
  color: #111827;
  font-size: 16px;
}

.examples {
  background-color: #f9fafb;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.examples-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
}

.examples-title {
  color: #8f8f8f;
  font-size: 14px;
  margin-bottom: 30px;
}

.examples-item-title {
  font-size: 12px;
  color: #8f8f8f;
  margin-bottom: 12px;

  display: block;
}

.examples-item {
  margin: 0 10px !important;
}

.examples-footnote p,
.examples-footnote ul,
.examples-footnote li {
  color: #8f8f8f;
  font-size: 14px;
}

.examples-footnote ul {
  padding-left: 8px;
}

.examples-footnote li {
  margin: 0 !important;
}

.story-note {
  background-color: #f9fafb;
  font-size: 14px;
  padding: 20px;
  margin-bottom: 12px;
  border-radius: 8px;
}

.story-note > div {
  max-width: 600px;
}

/** SB Docs Preview **/
.sbdocs-preview {
  width: 100vw;
  left: mindlabsc((100vw - 1200px) / -2);
  box-shadow: none !important;
  margin: 0 !important;
  border: none !important;
  border-radius: none !important;
}

.docs-story > div:first-child {
  padding: 0 !important;
  margin: 0 !important;
}

.docs-story .innerZoomElementWrapper > div {
  border: none !important;
}

.sb-main-padded {
  padding: 0 !important;
}

@media screen and (max-width: 1200px) {
  .sbdocs-preview {
    left: -24px;
    width: mindlabsc(100vw + 48px);
    padding-left: 12px;
    padding-right: 12px;
  }

  .sbdocs.sbdocs-content {
    padding: 24px !important;
  }
}

.docs-story {
  padding: 24px !important;
}

@keyframes rainbow-bg {
  0% {
    background-color: #ff6b6b;
  }
  14% {
    background-color: #ff9f43;
  }
  28% {
    background-color: #ffea00;
  }
  42% {
    background-color: #52c41a;
  }
  57% {
    background-color: #1890ff;
  }
  71% {
    background-color: #7b61ff;
  }
  85% {
    background-color: #ff85c0;
  }
  100% {
    background-color: #ff6b6b;
  }
}

.animate-bg-gradient {
  animation: rainbow-bg 5s ease-in-out infinite;
}
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 240 5.9% 10%;
    --primary-foreground: 0 0% 98%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 72.22% 50.59%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 240 5% 64.9%;
    --radius: 0.5rem;

    --chart-1: 12 76% 61%;
    --chart-2: 173 58% 39%;
    --chart-3: 197 37% 24%;
    --chart-4: 43 74% 66%;
    --chart-5: 27 87% 67%;
  }

  .dark {
    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;

    --chart-1: 220 70% 50%;
    --chart-2: 160 60% 45%;
    --chart-3: 30 80% 55%;
    --chart-4: 280 65% 60%;
    --chart-5: 340 75% 55%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  html {
    @apply scroll-smooth;
  }
  body {
    @apply bg-background text-foreground;
    /* font-feature-settings: "rlig" 1, "calt" 1; */
    font-synthesis-weight: none;
    text-rendering: optimizeLegibility;
  }
}

@layer utilities {
  .step {
    counter-increment: step;
  }

  .step:before {
    @apply absolute w-9 h-9 bg-muted rounded-full font-mono font-medium text-center text-base inline-flex items-center justify-center -indent-px border-4 border-background;
    @apply ml-[-50px] mt-[-4px];
    content: counter(step);
  }

  .chunk-container {
    @apply shadow-none;
  }

  .chunk-container::after {
    content: '';
    @apply absolute -inset-4 shadow-xl rounded-xl border;
  }
}

@media (max-width: 640px) {
  .container {
    @apply px-4;
  }
}
